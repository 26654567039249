<template>
  <div>
    <CRow>
      <CCol sm="12">
        <TravelsTable
          :items="travels"
          hover
          striped
          border
          small
          fixed
          caption="Viajes"
          @refresh="refreshItems"
          @trefresh="refreshTab"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import TravelsTable from '../../components/travels/TravelsTable.vue'
import travels from '../../services/travels';
import store from '../../store'

export default {
  name: 'Travels',
  components: { TravelsTable },
  data: function () {
		return {
            travels: []
        }
  },
  mounted: async function() {
    this.loading();
    // let response = await travels.get();

    // if(response.type == "success"){
    //   this.travels = response.data;
    // }

    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.travels = await items;
      this.loaded();
    },
    async refreshTab (tab,period){
      this.loading();
      let response = await travels.getBySchool(tab,period);

      if(response.type == "success"){
        this.travels = response.data;
      }
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
